.ProjectLogo {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}