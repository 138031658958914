@import "../../../assets/scss/partials/_colors";

.Row {
  background: #fff; /* fallback for old browsers */
}

.Row:active {
  background: #efefef;
}

.Votes,
.Rank {
  padding: 1rem 0;
  display: inline-block;
}

.Title {
  font-weight: 700;
}

.Title,
.Subtitle {
  color: $gray-900;
  padding: 0 1rem;
  display: inline-block;
}

.Subtitle {
  font-size: 0.875rem;
}

.Row:active .Rank {
  color: #fff;
}

.Rank {
  font-size: 24px;
}

.Votes {
  font-size: 14px;
  font-weight: 700;
}

.Link:visited,
.Link:active,
.Link:hover,
.Link:focus {
  text-decoration: none;
}