@media screen and (max-width: 576px) {
  .Slider {
    display: flex;
    flex-direction: column;
    height: 220px;
    max-height: 220px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 90%;
  }
}

