@import "../../assets/scss/partials/_colors";

.ProjectListLink:hover {
  text-decoration: none;
}

.SearchWrapper > .SearchField {
  padding-left: 36px;
}

.SearchIcon {
  color: $gray-600;
  left: 10px;
  position: absolute;
  top: 12px;
  z-index: 4;
}

:export {
  primaryColor: $primary;
  btnPaddingY: .375rem;
  btnPaddingX: .75rem;
}
