
.CountdownWrapper{
  //background-color: lightblue;
  position: relative;

}

@keyframes messageAnimation {
  0% {opacity: 0}
  20% {opacity: 0}
  40% {opacity: 1}

  60% {opacity: 1}
  80% {opacity: 0}
  100% {opacity: 0}
}
@keyframes timerAnimation {
  0% {opacity: 1}
  10% {opacity: 1}
  20% {opacity: 0}
  80% {opacity: 0}
  90% {opacity: 1}
  100% {opacity: 1}
}


.animatedElement{
  left: 0;
  top: 0;
}


.CountDownMessage{
  animation: messageAnimation 8s linear infinite forwards;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.CountdownTimer{
  animation: timerAnimation 8s linear infinite forwards;
}