.Navbar {
  background-size: auto;
  background-repeat: no-repeat;
}

.NavbarBrand {
  max-width: 200px;
}

.NavLink {
  font-size: 1.5rem;
}

@media screen and (min-width: 576px) {
  .NavbarBrand {
    max-width: none;
  }

  .NavLink {
    font-size: 1rem;
  }
}
