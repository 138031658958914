.Dropzone {
  border-width: 1px;
  border-style: dashed;
  border-radius: 5px;
  text-align: center;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
}

.dropzoneDisabled {
  border-color: gray;
  color: gray;
}

.dropzoneEnabled {
  border-color: #65B32E;
  color: #65B32E;
}

/*  -------------------------------------   */
.MediaFile {
  .cardImageOverlay {
    width: 100%;
    border: none;
  }
  .CardImg {
    border-radius: calc(0.25rem - 1px);
  }
}

.deleteArea {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  padding: 10px;
  .buttonDelete {
    background-color: rgba(240, 0, 0, 1);
    border: none;
    padding: 0;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-block;
    .icon {
      opacity: 1;
      vertical-align: middle;
    }
  }
  .buttonDelete[disabled] {
    background-color: #707070;
    opacity: 1;
  }
  .buttonDelete:hover {
    background-color: rgba(255, 0, 0, 1);
  }
}

.Loading {
  .CardImg {
    filter: opacity(0);
  }

}
.cardImageOverlay {
  background-color: #fff;
  height: 100%;
}

.spinnerParentShow {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.spinnerParentHide {
  display: none;
}

