.Modal {
    box-sizing: border-box;
    left: 5%;
    max-width: 500px;
    padding: 16px;
    position: fixed;
    top: 30%;
    transition: all 0.4s ease-out;
    width: 90%;
    z-index: 1072;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}