// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}          { border: $border-width solid $border-color; }
    .border#{$infix}-top      { border-top: $border-width solid $border-color; }
    .border#{$infix}-right    { border-right: 1px solid #ccc; }
    .border#{$infix}-bottom   { border-bottom: $border-width solid $border-color; }
    .border#{$infix}-left     { border-left: $border-width solid $border-color; }

    .border#{$infix}-0        { border: 0; }
    .border#{$infix}-top-0    { border-top: 0; }
    .border#{$infix}-right-0  { border-right: 0; }
    .border#{$infix}-bottom-0 { border-bottom: 0; }
    .border#{$infix}-left-0   { border-left: 0; }
  }
}

//.border-xs-right { border-right: 1px solid #ccc; }