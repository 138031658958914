.Slide {
  width: 90%;
  flex-shrink: 0;
  height: 100%;
  margin-right: 8px;
  max-height: 200px;
  overflow: hidden;
}

@media screen and (min-width: 576px) {
  .Slide {
    height: auto;
    margin-right: auto;
    max-height: 100%;
    overflow: auto;
  }
}
